div.login {
  margin-top: 30px;
}

div.notifications {
  /*position: fixed;
  top: 0;left: 0;right: 0;*/
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px;
  border-radius: 4px;
  background-color: #fff;
  font: normal 12px HelveticaNeue, Helvetica, Arial, sans-serif;
  z-index: 2000;
  color: #000;
  background-image: linear-gradient("to bottom", #FFF, #DBDBDB);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
}
div.notifications .notification {
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid #C1C1C1;
  box-shadow: 0px 1px 0px #FFF;
  border-radius: 4px;
}
div.notifications .notification:last-child {
  border-bottom: none;
  box-shadow: 0px 0px 0px transparent;
}
div.notifications .notification h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  padding-top: 5px;
  color: #010000;
}
div.notifications .notification p {
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}
div.notifications .notification .gauche {
  position: absolute;
  padding: 0 10px;
  height: 100%;
  width: 60px;
  top: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
div.notifications .notification .droite {
  margin-left: 70px;
}
div.notifications .notification .icon {
  font-family: "Entypo";
  font-size: 60px;
  line-height: 0px;
  text-shadow: 0, 1, 0, #fff;
  margin-top: 10px;
  text-align: center;
}
div.notifications .notification .img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: 50% 50%;
  background-size: auto 100%;
  box-shadow: rgba(0, 0, 0, 0.4) -1px 0px 16px inset;
}
div.notifications .success {
  background-color: #1a85bc;
  color: #010000;
  background-color: #a2e300;
}
div.notifications .error {
  background-color: #FA565D;
  color: #010000;
  background-color: #FA565D;
}